<template>
  <div>
    <p class="text-2xl mb-6">Arqueo de caja</p>
    <reporte-arqueo-caja-detallado :filtrosShow="true" :exportar="['EXCEL']"></reporte-arqueo-caja-detallado>
  </div>


</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

// demos
import ReporteArqueoCajaDetallado from './componentes/ReporteArqueoCajaDetallado.vue';
export default {
  components: {
    ReporteArqueoCajaDetallado
  },
  setup() {


    return {}
  },
}
</script>
